import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  type IdGetterObj,
} from '@apollo/client';
import {BatchHttpLink} from '@apollo/client/link/batch-http';
import {onError as onRequestError} from '@apollo/client/link/error';
import {fetchWithCsrf} from './customFetchers/fetchWithCsrf';
import {createRetryLink, RetryLinkConfig} from './helpers';

interface WebflowIdGetterObj extends IdGetterObj {
  cmsLocaleId?: string | null;
}

// Returns an ApolloClient instance
// https://www.apollographql.com/docs/react/
export const createApolloClient = ({
  origin = '',
  path,
  publicationId,
  previewKey,
  ssrMode = false,
  credentials = 'same-origin',
  headers = {},
  useCsrf = false,
  retryConfig,
  onError,
  disableBatching = false,
}: {
  origin?: string;
  path: string;
  publicationId?: string;
  previewKey?: string;
  ssrMode?: boolean;
  credentials?: string;
  useCsrf?: boolean;
  retryConfig?: RetryLinkConfig;
  onError?: (arg1: {
    response: {
      data?: Record<any, any>;
      errors?: Array<{
        message: string;
        path?: string[];
      }> | null;
    };
  }) => any;
  headers?: Record<string, string>;
  disableBatching?: boolean;
}) => {
  const uri = buildApolloClientUri({origin, path, publicationId, previewKey});

  const requestHeaders: Record<string, string> = {
    Accept: 'application/json',
  };

  Object.keys(headers).forEach((headerKey) => {
    // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
    requestHeaders[headerKey] = headers[headerKey];
  });

  const batchLinkArgs: {
    uri: string;
    headers: Record<string, string>;
    credentials: string;
    batchMax?: number;
    batchInterval?: number;
    fetch?: typeof fetchWithCsrf;
  } = {
    uri,
    headers: requestHeaders,
    credentials,
  };

  // Disable batching
  if (disableBatching) {
    batchLinkArgs.batchMax = 1;
    batchLinkArgs.batchInterval = 0;
  }

  // Fetch using a CSRF token if toggled
  if (useCsrf) {
    batchLinkArgs.fetch = fetchWithCsrf;
  }

  const batchLink = new BatchHttpLink(batchLinkArgs);

  const links: Array<any> = [];

  if (retryConfig) {
    links.push(createRetryLink(retryConfig));
  }

  if (onError) {
    // @ts-expect-error - TS2345 - Argument of type '(arg1: { response: { data?: Record<any, any> | undefined; errors?: { message: string; path?: string[] | undefined; }[] | null | undefined; }; }) => any' is not assignable to parameter of type 'ErrorHandler'.
    links.push(onRequestError(onError));
  }

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([...links, batchLink]),
    cache: new InMemoryCache({
      dataIdFromObject: (object: WebflowIdGetterObj) => {
        switch (object.__typename) {
          case 'sku_props':
            return undefined;
          case 'commerce_subscription': {
            return object._id;
          }
          case 'collections':
            // `collections` does not have an ID, so we must
            // use a hard-coded string so that Apollo cache
            // can merge the `collections` objects together.
            return object.__typename;
          default: {
            if (object.cmsLocaleId) {
              return `${object.id}_${object.cmsLocaleId}`;
            }
            return object.id;
          }
        }
      },
    }),
    ssrMode,
  });
  return apolloClient;
};

export const buildApolloClientUri = ({
  origin = '',
  path,
  publicationId,
  previewKey,
}: {
  origin?: string;
  path: string;
  publicationId?: string;
  previewKey?: string;
}) => {
  const params: Array<any | string> = [];
  if (publicationId) {
    params.push(`pub=${publicationId}`);
  }
  if (previewKey) {
    params.push(`preview=${previewKey}`);
  }
  // Replace multiple slashes with single slashes
  const cleanPath = `${origin}${path}`.replace(/([^:])\/\/+/g, '$1/');
  return `${cleanPath}${params.length ? `?${params.join('&')}` : ''}`;
};
